import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";
import axios from "axios";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const { ticketId } = useParams();
	const classes = useStyles();
	const history = useHistory();
	const [pesquisa, setToken] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	useEffect(() => {
			const fetchPesquisa = async () => {
			try {
			const response = await api.get('/settings/check-pesquisa-enable');
			const { isPesquisaEnable } = response.data;
			setToken(isPesquisaEnable);
			} catch (error) {
			toast.error("Erro ao obter status da pesquisa de satisfação.");
			}
		};
		fetchPesquisa();
	}, []);

	const handlePesquisa = async (startusticket, userId) => {
		try {
			
			const responseData = {
				ticketId: ticket.id,
				userId: userId || null,
				contact: ticket.contact,
                queueId: ticket.queue?.id,
			};
	
			const webhookUrl = 'https://hook.cosampa.com.br/webhook/18c11907-7136-494d-8d50-efd67a68c6f0';
			await axios.post(webhookUrl, responseData);
		} catch (err) {
			toastError(err);
		}
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		let startusticket = "pesquisa";
		try {
			if(status === "closed" && pesquisa === true){
				handlePesquisa(startusticket, userId, ticketId.contact?.id);
				await api.put(`/tickets/${ticket.id}`, {
					status: startusticket,
					userId: userId || null,
				});
			}else {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});
		}

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
